import './styles.scss';
import React from 'react';
import Banner from 'components/Banner';
import { Row, Col } from 'antd';
import PackageItem from 'components/Package';
import History from './component/history';
import ReferralGift from 'components/ReferralGift';
import BackLink from 'components/BackLink';

const dataPacake = [
  {
    name: 'MM Plus',
    description: 'For most people who want to do crypto trading operations in a smart way',
    uses: ['Bot trade function', 'Bot auto detect function'],
    price: '$89',
    priceSale: '$49',
    status: 'active',
    duration: 'Month',
    buy: 'Get Started',
  },
  {
    name: 'MM Pro',
    description: 'For most businesses that want to use advanced functionality to trade cryptocurrencies',
    uses: ['Bot trade function', 'Bot auto detect function', 'Bot trading volume function', 'Bot target price function'],
    price: '$189',
    priceSale: '$99',
    status: 'inactive',
    duration: 'Month',
    buy: 'Comming Soon',
  },
];

const Billing = () => (
  <>
    <div className="warp-page-billing">
      <Banner showLink />

      <div className="warp-billing-content">
        <div className="billing-content">
          <div className="go-back">
            <BackLink label="Account" />
          </div>

          <Row
            className="box-start-exchange"
            justify="space-between"
            align="middle"
            style={{ backgroundImage: `url('/images/billing/background-start.png')` }}
          >
            <Col xs={{ span: 24 }} sm={{ span: 14 }} md={{ span: 15 }} lg={{ span: 15 }}>
              <div className="start-exchange">
                <p>Start your exchange</p>
                <p>Learn directly exchange trading bot</p>
                <button type="button">Deposit</button>
              </div>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 10 }} md={{ span: 9 }} lg={{ span: 9 }}>
              <div className="acount-balance">
                <div className="item-balance">
                  <div>
                    <img src="/images/icons/icon-wallet.png" alt="" />
                  </div>
                  <div>
                    <p>Acount Balance</p>
                    <p>$53</p>
                  </div>
                </div>

                <div className="item-balance">
                  <div>
                    <img src="/images/icons/icon-commission.png" alt="" />
                  </div>
                  <div>
                    <p>Commission</p>
                    <p>500 $</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <ReferralGift />

          <Row gutter={[24, 18]}>
            {dataPacake.map((item, index) => (
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} key={index}>
                <PackageItem data={item} />
              </Col>
            ))}
          </Row>

          <History />
        </div>
      </div>
    </div>
  </>
);

export default Billing;
