import './styles.scss';
import React from 'react';
import InputWithButton from 'widgets/InputWithButton';

const Register = () => (
  <div className="warp-register-home ">
    <div className="register-home">
      <div className="title-block">
        <p>Ready to start trading cryptocurrency?</p>
        <h2>Free To Start Today</h2>
      </div>
      <InputWithButton placeholder="Enter your email address" size="larger" button="Get Started" />
    </div>
  </div>
);

export default Register;
