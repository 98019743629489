import './styles.scss';
import React from 'react';
import { Table } from 'antd';

const columnsMB = [
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time',
    render: (text) => <p className="time">{text}</p>,
  },
  {
    title: 'Action/IP',
    dataIndex: 'action',
    key: 'action',
    render: (text, record) => (
      <div>
        <p className="action">{record.action}</p>
        <p className="ip">{record.ip}</p>
      </div>
    ),
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    render: (text) => <p className="location">{text}</p>,
  },
];

const columnsPC = [
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time',
    render: (text) => <p className="time">{text}</p>,
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    render: (text) => <p className="action">{text}</p>,
  },
  {
    title: 'IP',
    dataIndex: 'ip',
    key: 'ip',
    render: (text) => <p className="ip">{text}</p>,
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    render: (text) => <p className="location">{text}</p>,
  },
];

const data = [
  {
    id: 1,
    time: `2022-02-09 14:01:48`,
    action: 'Login',
    ip: '171.246.107.217',
    location: 'Vietnam-Ho Chi Minh-Ho Chi Minh City',
  },
  {
    id: 2,
    time: `2022-02-09 14:01:48`,
    action: 'Log out',
    ip: '171.246.107.217',
    location: 'Vietnam-Ho Chi Minh-Ho Chi Minh City',
  },
  {
    id: 3,
    time: `2022-02-09 14:01:48`,
    action: 'Login',
    ip: '171.246.107.217',
    location: 'Vietnam-Ho Chi Minh-Ho Chi Minh City',
  },
  {
    id: 4,
    time: `2022-02-09 14:01:48`,
    action: 'Login',
    ip: '171.246.107.217',
    location: 'Vietnam-Ho Chi Minh-Ho Chi Minh City',
  },
  {
    id: 5,
    time: `2022-02-09 14:01:48`,
    action: 'Login',
    ip: '171.246.107.217',
    location: 'Vietnam-Ho Chi Minh-Ho Chi Minh City',
  },
  {
    id: 6,
    time: `2022-02-09 14:01:48`,
    action: 'Login',
    ip: '171.246.107.217',
    location: 'Vietnam-Ho Chi Minh-Ho Chi Minh City',
  },
];

const HistoryLogin = () => (
  <div className="warp-account-history-login">
    <div className="account-history-login">
      <p>Recent logins</p>

      <Table rowKey="id" className="table-history" columns={window.innerWidth >= 991 ? columnsPC : columnsMB} dataSource={data} />
    </div>
  </div>
);

export default HistoryLogin;
