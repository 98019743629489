import './styles.scss';
import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';

const Control = () => (
  <div className="warp-account-control">
    <div className="account-control">
      <Row gutter={[20, 16]} justify="center">
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
          <div className="item-control active">
            <div>
              <p>Acount Balance</p>
              <p>$53</p>
            </div>
            <div>
              <Link to="account/deposit">
                <button type="button">
                  <img src="/images/icons/icon-wallet-single.png" alt="" /> Deposit
                </button>
              </Link>
            </div>
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
          <div className="item-control">
            <div>
              <p>Referral Click</p>
              <p>5000</p>
            </div>
            <div>
              <img src="/images/icons/icon-earth.png" alt="" />
            </div>
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
          <div className="item-control">
            <div>
              <p>Successful Conversion</p>
              <p>50</p>
            </div>
            <div>
              <img src="/images/icons/icon-ticked.png" alt="" />
            </div>
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
          <div className="item-control">
            <div>
              <p>Commission</p>
              <p>500 $</p>
            </div>
            <div>
              <img src="/images/icons/icon-rocket-frame.png" alt="" />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  </div>
);

export default Control;
