import React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Row, Col } from 'antd';
import './styles.scss';

const BlogItem = ({ data, ...props }) => (
  <div className="blog-item" {...props}>
    <Row>
      <Col md={{ span: 24 }} lg={{ span: 12 }}>
        <div className="content-text">
          <h4>{data.title}</h4>
          <p>{data.description}</p>
          <Link to={data.link}>Let’s See</Link>
        </div>
      </Col>
      <Col md={{ span: 24 }} lg={{ span: 12 }}>
        <div className="content-image">
          <img src={data.image} alt={data.title} />
        </div>
      </Col>
    </Row>
  </div>
);

export default injectIntl(BlogItem);
