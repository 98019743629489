import './styles.scss';
import React from 'react';
import Banner from './components/Banner';
import BlogItem from './components/BlogItem';

const dataBlog = [
  {
    id: 1,
    title: `Don't Miss The Moment`,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet urna mi et semper aenean tristique gravida tincidunt. Auctor in ullamcorper vitae neque, adipiscing augue',
    link: '/blog/detail/1',
    image: '/images/blog/blog-image.png',
  },
  {
    id: 2,
    title: `Don't Miss The Moment`,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet urna mi et semper aenean tristique gravida tincidunt. Auctor in ullamcorper vitae neque, adipiscing augue',
    link: '/blog/detail/2',
    image: '/images/blog/blog-image.png',
  },
  {
    id: 3,
    title: `Don't Miss The Moment`,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet urna mi et semper aenean tristique gravida tincidunt. Auctor in ullamcorper vitae neque, adipiscing augue',
    link: '/blog/detail/3',
    image: '/images/blog/blog-image.png',
  },
  {
    id: 4,
    title: `Don't Miss The Moment`,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet urna mi et semper aenean tristique gravida tincidunt. Auctor in ullamcorper vitae neque, adipiscing augue',
    link: '/blog/detail/4',
    image: '/images/blog/blog-image.png',
  },
];

const Blog = () => (
  <div className="warp-blog">
    <Banner />

    <div className="container">
      {dataBlog.map((item) => (
        <BlogItem data={item} key={item.id} />
      ))}
    </div>
  </div>
);

export default Blog;
