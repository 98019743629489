import './styles.scss';
import React from 'react';
import Banner from 'components/Banner';
import Form from './component/form';

const Deposit = () => (
  <>
    <div className="warp-page-deposit">
      <Banner showLink />
      <Form />
    </div>
  </>
);

export default Deposit;
