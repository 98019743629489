import './styles.scss';
import React from 'react';

const Banner = () => (
  <div className="warp-account-banner">
    <img className="background-banner" src="/images/account/banner.png" alt="background" />
    <div className="account-banner">
      <div className="info-user-right">
        <div>
          <img src="/images/account/avata.png" alt="" />
          <img className="avatar-edit" src="/images/account/edit.png" alt="" />
        </div>
        <div>
          <p>
            Mina Ng <img src="/images/account/edit.png" alt="" />
          </p>
          <p>minang@mmrocket.io</p>
        </div>
      </div>

      <div className="info-user-left">
        <div>
          <p>MM Pro</p>
        </div>
        <div>
          <p>
            <img src="/images/account/clock.png" alt="" /> Remain:
          </p>
          <p>2 mon 15 d 20 h</p>
        </div>
      </div>
    </div>
  </div>
);

export default Banner;
