import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import Cookies from 'js-cookie';
import { ToastContainer } from 'react-toastify';
import 'antd/dist/antd.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/global.scss';
import { languages } from 'language/config';
import { PrivateLayout, PublicLayout } from 'layouts';
import { CACHE_TOKEN } from 'constants/index';
import fetchHelper from 'helpers/FetchHelper';
import Home from 'modules/home';
import Blog from 'modules/blog';
import SignIn from 'modules/auth/pages/signin';
import SignUp from 'modules/auth/pages/signup';
import ForgotPassword from 'modules/auth/pages/forgotPassword';
import ResetPassword from 'modules/auth/pages/resetPassword';
import BlogDetail from 'modules/blogDetail';
import Notification from 'modules/notification';
import Page404 from 'modules/404';
import ComingSoon from 'modules/comingSoon';
import Deposit from 'modules/deposit';
import Billing from 'modules/billing';
import Feature from 'modules/feature';
import Wallet from 'modules/wallet';
import Market from 'modules/market';
import Account from 'modules/account';
import Security from 'modules/security';
import ReferralProgram from 'modules/referralProgram';

const isLogin = () => {
  const authToken = Cookies.get(CACHE_TOKEN);
  if (authToken) {
    fetchHelper.addDefaultHeader('Authorization', `Bearer ${authToken}`);
  }
  return Boolean(authToken);
};

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isLogin() ? (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      ) : (
        <PublicLayout {...rest}>
          <Component {...props} />
        </PublicLayout>
      )
    }
  />
);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isLogin() ? (
        <PrivateLayout {...rest}>
          <Component {...props} />
        </PrivateLayout>
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

function App() {
  const { language } = useSelector((state) => state.user);

  return (
    <>
      <ToastContainer />
      <IntlProvider locale={language || 'en'} messages={languages[language]}>
        <Router>
          <Switch>
            {/* Private */}
            <PrivateRoute exact path="/market" component={Market} />

            {/* Public */}
            <PublicRoute exact path="/" component={Home} />
            <PublicRoute exact path="/signup" component={SignUp} />
            <PublicRoute exact path="/login" component={SignIn} />
            <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
            <PublicRoute exact path="/reset-password" component={ResetPassword} />
            <PublicRoute exact path="/blog" component={Blog} />
            <PublicRoute exact path="/feature" component={Feature} />
            <PublicRoute exact path="/wallet" component={Wallet} />
            <PublicRoute exact path="/blog/detail/:id" component={BlogDetail} />

            <PublicRoute exact path="/account" component={Account} />
            <PublicRoute exact path="/account/deposit" component={Deposit} />
            <PublicRoute exact path="/account/billing" component={Billing} />
            <PublicRoute exact path="/account/notification" component={Notification} />
            <PublicRoute exact path="/account/security" component={Security} />
            <PublicRoute exact path="/account/referral-program" component={ReferralProgram} />

            <PublicRoute exact path="/coming-soon" component={ComingSoon} />
            <PublicRoute exact path="/404" component={Page404} />

            {/* Other */}
            <Route path="" component={() => <Redirect to="/" />} />
          </Switch>
        </Router>
      </IntlProvider>
    </>
  );
}

export default App;
