import React from 'react';
import Banner from './components/banner';
import Feature from './components/feature';
import User from './components/user';
import Package from './components/package';
// import Backer from './components/backer';
import Trading from './components/trading';
import Register from './components/register';
import './styles.scss';
import OneClickOneApi from 'components/OneClickOneApi';

const Home = () => (
  <>
    <div className="warper-home">
      <Banner />
      {/* <Chart /> */}
      <Feature />
      <User />
      <OneClickOneApi />
      <Package />
      {/* <Backer /> */}
      <Trading />
      <Register />
    </div>
  </>
);

export default Home;
