import React, { useState } from 'react';
import './styles.scss';

const ShareLink = ({ text, notica }) => {
  const [showNoti, setShowNoti] = useState(false);

  return (
    <div className="box-show-address-text">
      <div>
        <p>0xb99eac020a6b9370d1b36da40f810f5c468369fa</p>
      </div>
      <div>
        <img
          role="presentation"
          onClick={() => {
            if (navigator.clipboard) {
              navigator.clipboard.writeText(text);
              setShowNoti(true);
              setTimeout(() => {
                setShowNoti(false);
              }, 1000);

              if (showNoti) setShowNoti(false);
            }
          }}
          src="/images/icons/icon-copy.png"
          alt=""
        />
        <img src="/images/icons/icon-qr.png" alt="" />
        {showNoti && <div className="tooltip">{notica || 'Copied'}</div>}
      </div>
    </div>
  );
};

export default ShareLink;
