import './styles.scss';
import React from 'react';
import { Row, Col } from 'antd';
import PointSecurity from 'components/PointSecurity';
import { Link } from 'react-router-dom';

const dataAction = [
  {
    id: 1,
    name: 'Billing',
    description:
      'Only Deposit to purchase a new package to use advanced bot features. Users are required to purchase a new package to use advanced bot features',
    image: '/images/icons/card-atm.png',
    link: 'account/billing',
  },
  {
    id: 2,
    name: 'Security',
    description: `Account risk level: `,
    image: '/images/icons/icon-security-safe.png',
    link: 'account/security',
  },
  {
    id: 3,
    name: 'Referral Program',
    description: 'In return , once the person you refer spends from $50 as a MM Rocket customer, we give you $10 commission',
    image: '/images/icons/user.png',
    link: 'account/referral-program',
  },
  {
    id: 4,
    name: 'Notification',
    description:
      'User can sellect channel to recieve notify message. Subscribe to get update and notify our exchange and products',
    image: '/images/icons/notification.png',
    link: 'account/notification',
  },
];

const Action = () => (
  <div className="warp-account-action">
    <div className="account-action">
      <Row gutter={[24, 24]} justify="center">
        {dataAction.map((item) => (
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
            <Link to={item.link}>
              <div className="item-action">
                <div className="item-title">
                  <p>{item.name}</p>
                  <img src={item.image} alt={item.name} />
                </div>
                <div className="item-description">
                  <p>
                    {item.description} {item.id === 2 ? <PointSecurity point={3} /> : ''}
                  </p>
                </div>
              </div>
            </Link>
          </Col>
        ))}
      </Row>
    </div>
  </div>
);

export default Action;
