import React from 'react';
import Svg from '../Svg';

const Icon = (props) => (
  <Svg viewBox="0 0 22 22" {...props}>
    <path
      d="M11 1.375C5.68477 1.375 1.375 5.68477 1.375 11C1.375 16.3152 5.68477 20.625 11 20.625C16.3152 20.625 20.625 16.3152 20.625 11C20.625 5.68477 16.3152 1.375 11 1.375ZM11 18.9922C6.58711 18.9922 3.00781 15.4129 3.00781 11C3.00781 6.58711 6.58711 3.00781 11 3.00781C15.4129 3.00781 18.9922 6.58711 18.9922 11C18.9922 15.4129 15.4129 18.9922 11 18.9922Z"
      fill="#F8AF39"
    />
    <path
      d="M9.96875 14.7812C9.96875 15.0548 10.0774 15.3171 10.2708 15.5105C10.4642 15.7038 10.7265 15.8125 11 15.8125C11.2735 15.8125 11.5358 15.7038 11.7292 15.5105C11.9226 15.3171 12.0312 15.0548 12.0312 14.7812C12.0312 14.5077 11.9226 14.2454 11.7292 14.052C11.5358 13.8586 11.2735 13.75 11 13.75C10.7265 13.75 10.4642 13.8586 10.2708 14.052C10.0774 14.2454 9.96875 14.5077 9.96875 14.7812ZM10.4844 12.375H11.5156C11.6102 12.375 11.6875 12.2977 11.6875 12.2031V6.35938C11.6875 6.26484 11.6102 6.1875 11.5156 6.1875H10.4844C10.3898 6.1875 10.3125 6.26484 10.3125 6.35938V12.2031C10.3125 12.2977 10.3898 12.375 10.4844 12.375Z"
      fill="#F8AF39"
    />
  </Svg>
);

export default Icon;
