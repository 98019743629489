import './styles.scss';
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    paritialVisibilityGutter: 180,
  },
  tablet: {
    breakpoint: { max: 1024, min: 500 },
    items: 2,
  },
  isMobile: {
    breakpoint: { max: 500, min: 365 },
    items: 1,
  },
  isSmallMobile: {
    breakpoint: { max: 365, min: 0 },
    items: 1,
  },
};

const detailBlog = () => (
  <>
    <div className="warp-blog-detail">
      <div className="container">
        <div className="blog-detail-content">
          <div className="banner-blog-detail">
            <img src="/images/blog/image-blog-detail.png" alt="" />
          </div>

          <div className="header-blog">
            <p>Blogging to Reading!</p>
            <h1 className="blog-title">Metaverse , Brands, and Experience</h1>
            <p className="blog-created-at">28 July 2022 : Consected foly</p>
            <img className="backgound-dot" src="/images/blog/background-dot.png" alt="" />
          </div>

          <div className="body-blog">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida iaculis sit dignissim ridiculus phasellus quis
              condimentum venenatis, cras. Vehicula turpis suspendisse elit elementum, ut. Quis mattis tempus mi, sit dignissim
              justo vehicula gravida sed.
            </p>
            <h2>Lorem Ipsum</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam condimentum nec amet consectetur consectetur
              quisque. Quam arcu lorem ac odio lorem. Aliquam nunc vitae, est dignissim justo. Posuere amet, mi quis molestie
              tellus, faucibus gravida. Sed erat feugiat pretium sagittis orci risus enim. Et sed ut posuere at est. Adipiscing
              suscipit varius consequat pulvinar id maecenas. Phasellus pulvinar libero, tincidunt praesent. Facilisis{' '}
            </p>
            <h2>Consectetur adipiscing elit</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec est, nulla fermentum nisl. Etiam eu quam ipsum
              natoque justo. Malesuada feugiat netus tellus proin blandit. Donec luctus purus fringilla diam amet lectus vitae.
              Nisi, dolor nunc nisl interdum pharetra. Turpis eleifend turpis netus hac et. Neque lorem ipsum vulputate posuere
              imperdiet aliquam ac tincidunt sed. Diam non vulputate ligula tellus leo lacus urna est, eget. Proin nec tortor nunc
              libero lacus, ac. Et placerat in rhoncus, orci, cursus non ut eget vitae. Maecenas sed justo, pellentesque id in
              enim.
              <br /> Quis eget purus netus praesent. Dis molestie eget enim vitae. Nullam donec tellus pellentesque sodales
              quisque volutpat. Enim, eget pellentesque vitae, eget nulla placerat porttitor quam leo. At ut massa purus hendrerit
              tincidunt velit. Morbi diam dictumst consectetur ipsum sed enim adipiscing nibh sodales. Pharetra netus tellus in
              dui blandit mauris gravida sed
            </p>

            <blockquote>
              “Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec est, nulla fermentum nisl. Etiam eu quam ipsum
              natoque justo. Malesuada feugiat netus tellus proin blandit.”
            </blockquote>

            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam condimentum nec amet consectetur consectetur
              quisque. Quam arcu lorem ac odio lorem. Aliquam nunc vitae, est dignissim justo. Posuere amet, mi quis molestie
              tellus, faucibus gravida. Sed erat feugiat pretium sagittis orci risus enim. Et sed ut posuere at est. Adipiscing
              suscipit
            </p>
          </div>

          <div className="footer-blog">
            <p>Share this blog</p>
            <div className="box-share-social">
              <div className="list-social">
                <img src="/images/icons/fb.png" alt="" />
                <img src="/images/icons/skype.png" alt="" />
                <img src="/images/icons/tw.png" alt="" />
              </div>
              <p>28 July 2022 : Consected foly</p>
            </div>
          </div>
        </div>

        <div className="slider-blog">
          <div className="title-blog-next">
            <p>Blog</p>
            <p>Next Crypto Market Blog</p>
          </div>
          <Carousel responsive={responsive} partialVisible>
            <div className="item-blog-slider">
              <img src="/images/blog/item-1.png" alt="" />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. A, et,</p>
            </div>
            <div className="item-blog-slider">
              <img src="/images/blog/item-1.png" alt="" />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. A, et,</p>
            </div>
            <div className="item-blog-slider">
              <img src="/images/blog/item-1.png" alt="" />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. A, et,</p>
            </div>
            <div className="item-blog-slider">
              <img src="/images/blog/item-1.png" alt="" />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. A, et,</p>
            </div>
            <div className="item-blog-slider">
              <img src="/images/blog/item-1.png" alt="" />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. A, et,</p>
            </div>
            <div className="item-blog-slider">
              <img src="/images/blog/item-1.png" alt="" />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. A, et,</p>
            </div>
            <div className="item-blog-slider">
              <img src="/images/blog/item-1.png" alt="" />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. A, et,</p>
            </div>
            <div className="item-blog-slider">
              <img src="/images/blog/item-1.png" alt="" />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. A, et,</p>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  </>
);

export default detailBlog;
