import './styles.scss';
import React from 'react';
import { injectIntl } from 'react-intl';

const Banner = () => (
  <>
    <div className="container-blog">
      <div className="warp-banner">
        <div className="box-image">
          <img src="/images/icons/icon-rocket.png" alt="" />
          <img src="/images/icons/icon-alo.png" alt="" />
        </div>
        <h1>It imparts positive feelings about the Crypto Exchange.</h1>
        <p>Here we will bring you a great experience. Let's take a look at the latest news</p>
        <div className="list-dot">
          <img className="banner-dot" src="/images/icons/icon-dot-blue.png" alt="" />
          <img className="banner-dot" src="/images/icons/icon-dot-blue.png" alt="" />
          <img className="banner-dot" src="/images/icons/icon-dot-blue.png" alt="" />
          <img className="banner-dot" src="/images/icons/icon-dot-white.png" alt="" />
          <img className="banner-dot" src="/images/icons/icon-dot-white.png" alt="" />
          <div className="banner-rocket">
            <img src="/images/icons/rocket.png" alt="" />
            <img className="rocket-fire" src="/images/icons/fire.gif" alt="" />
          </div>
        </div>
      </div>
    </div>
  </>
);

export default injectIntl(Banner);
