import React, { useState } from 'react';
import './styles.scss';

const ShareLink = ({ text, notica, ...props }) => {
  const [showNoti, setShowNoti] = useState(false);

  return (
    <div className="box-copy-link" {...props}>
      <div className="link">
        <div>
          <p>{text}</p>
        </div>
      </div>
      <div
        className="icon-copy"
        role="presentation"
        onClick={() => {
          if (navigator.clipboard) {
            navigator.clipboard.writeText(text);
            setShowNoti(true);
            setTimeout(() => {
              setShowNoti(false);
            }, 1000);

            if (showNoti) setShowNoti(false);
          }
        }}
      >
        <img src="/images/icons/icon-copy.png" alt="copy" />
        {showNoti && <div className="tooltip">{notica || 'Copied'}</div>}
      </div>
    </div>
  );
};

export default ShareLink;
