import './styles.scss';
import React from 'react';
import { injectIntl } from 'react-intl';
import ItemFeature from './ItemFeature';

const dataFeature = [
  {
    name: 'Trigger Price',
    description: 'Control the price of a token, pair in 1 place',
    image: '/images/Home/feature/feature-1.png',
  },
  {
    name: 'Realtime data',
    description: 'Easy to listen new txns, trades On-Chain realtime with websocket channel',
    image: '/images/Home/feature/feature-2.png',
  },
  {
    name: 'Trading Volume',
    description: 'Watching trading data for ready-to-use Token, Pair, Wallet in 1 API key',
    image: '/images/Home/feature/feature-3.png',
  },
  {
    name: 'Transaction Alert',
    description: 'Add Watching Addresses to receive new transactions alert via webhook to your application',
    image: '/images/Home/feature/feature-4.png',
  },
];

const Feature = () => (
  <>
    <div className="warp-feature-home">
      <div className="feature-home">
        <div className="title-block">
          <h2>The most trusted cryptocurrency platform.</h2>
          <p>MMRocket has a variety of features that make it the best place to start Trading, Tracking, Tracing On-Chain data</p>
        </div>

        <div className="content">
          {dataFeature.map((item, index) => (
            <ItemFeature data={item} key={index} />
          ))}
        </div>

        <img className="background-home-2" src="/images/Home/background/bg-2.png" alt="" />
      </div>
    </div>

    {/* <div className="box-swap">
      <div className="title-block">
        <h2>One click, swap, trading all in one.</h2>
        <p>Become a crypto owner in minutes using our platform and quickly purchase top cryptocurrencies.</p>
      </div>

      <div className="box-swap-content">
        <div className="box-image-background">
          <div className="box-earth" style={{ background: `url('/images//Home/feature/mapbase.png')` }}>
            <img className="background-earth" src="/images/Home/feature/earth-dark.png" alt="" />
          </div>
          <img className="background-circle-1" src="/images/Home/feature/circle-1.png" alt="" />
          <img className="background-circle-2" src="/images/Home/feature/circle-2.png" alt="" />
          <img className="background-rocket" src="/images/Home/feature/rocket.png" alt="" />
          <img className="background-bitcoin" src="/images/Home/feature/bitcoin.png" alt="" />
          <img className="background-bnb" src="/images/Home/feature/bnb.png" alt="" />
          <img className="background-ethereum" src="/images/Home/feature/ethereum.png" alt="" />
          <img className="background-usdt" src="/images/Home/feature/usdt.png" alt="" />
        </div>

        <div className="specifications-box">
          {dataSpecifications.map((item, index) => (
            <div className="specifications-item" key={index}>
              <div>
                <img src={item.image} alt={item.name} />
              </div>
              <div>
                <p>{item.value}</p>
                <p>{item.name}</p>
              </div>
            </div>
          ))}
        </div>

        <BoxBuyToken />
      </div>

      <img className="background-home-3" src="/images/Home/background/bg-3.png" alt="" />
    </div> */}
  </>
);

export default injectIntl(Feature);
