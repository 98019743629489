import React from 'react';
import { Form } from 'antd';
import BackLink from 'components/BackLink';
import './styles.scss';
import { EditIcon, BackIcon } from 'widgets/Svg';
import Banner from 'components/Banner';

const Notification = () => {
  const [formEmail] = Form.useForm();
  const [formTelegram] = Form.useForm();

  const onSubmitEmail = () => {
    formEmail.validateFields().then((values) => {
      console.log(values);
    });
  };
  const onSubmitTelegram = () => {
    formTelegram.validateFields().then((values) => {
      console.log(values);
    });
  };

  return (
    <div className="notification-container fadeIn">
      <Banner showLink />
      <div className="notification-content">
        <div className="notification-body">
          <BackLink icon={<BackIcon />} label="Account" />
          <div className="notification-title">Received information about new event and the important information</div>
          <p className="notification-des">
            Register your address to receive the latest announcements from us. You will get the information more quickly
          </p>
          <Form layout="vertical" className="form-notification" form={formEmail} onFinish={onSubmitEmail}>
            <Form.Item
              label={
                <>
                  <span>Email</span>
                  <button type="submit" className="btn-active">
                    Active
                  </button>
                </>
              }
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please enter email field',
                },
              ]}
            >
              <div>
                <input placeholder="example@gmail.com" />
                <EditIcon />
              </div>
            </Form.Item>
          </Form>
          <Form layout="vertical" className="form-notification" form={formTelegram} onFinish={onSubmitTelegram}>
            <Form.Item
              label={
                <>
                  <span>Telegram</span>
                  <button type="submit" className="btn-disable">
                    Disable
                  </button>
                </>
              }
              name="telegram"
              rules={[
                {
                  required: true,
                  message: 'Please enter telegram field',
                },
              ]}
            >
              <div>
                <input placeholder="@yourname" />
                <EditIcon />
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Notification;
