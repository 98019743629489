import './styles.scss';
import React from 'react';
import { Row, Col } from 'antd';

const dataFeature = [
  {
    name: 'Target Price',
    description: 'Control the price chart of a token that is always at a set price in custom time',
    image: '/images/Home/feature/feature-1.png',
    action: 'Upgarde MM Plus',
  },
  {
    name: 'Smart Trading',
    description: 'Automatically buy or sell at you desired price',
    image: '/images/Home/feature/feature-2.png',
    action: 'Upgarde MM Plus',
  },
  {
    name: 'Trading Volume',
    description: 'Automatically buy and sell to increase trading volume but ensure assets are not consumed too much',
    image: '/images/Home/feature/feature-3.png',
    action: 'Coming soon',
  },
  {
    name: 'Auto Detect Envent',
    description: 'Automatically buy when the price hits the target and there are seller or vice versa',
    image: '/images/Home/feature/feature-4.png',
    action: 'Coming soon',
  },
];

const Feature = () => (
  <div className="warp-account-feature">
    <div className="account-feature">
      <p>Feature</p>
      <Row
        gutter={[
          { xs: 8, sm: 12, md: 16, lg: 33 },
          { xs: 8, sm: 12, md: 16, lg: 33 },
        ]}
        justify="center"
      >
        {dataFeature.map((item, index) => (
          <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 6 }} key={index}>
            <div className="item-feature">
              <div>
                <img src={item.image} alt={item.name} />
                <p className="item-title">{item.name}</p>
                <p className="item-description">{item.description}</p>
              </div>
              <p>{item.action}</p>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  </div>
);

export default Feature;
