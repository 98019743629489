import './styles.scss';
import React from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

const Banner = () => {
  React.useEffect(() => {}, []);
  return (
    <>
      <div className="banner-home">
        <div className="content ">
          <h1>All-in-One Blockchain Data APIs</h1>
          <Link to="/coming-soon">
            <button type="button">Get Started For Free Now</button>
          </Link>
        </div>
        <img className="background-home-1" src="/images/Home/background/bg-1.png" alt="" />
      </div>
    </>
  );
};

export default injectIntl(Banner);
