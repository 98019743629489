import React from 'react';
import './styles.scss';

const ReferralGift = ({ ...props }) => (
  <div className="referral-gift" {...props}>
    <p>Gets $10 in credit for each you refer to make depositing</p>
    <p>
      Each for person you refer when depositing from $50 you will receive a $10 commissions. There is no limit to the amount of
      credit you can earn through referrals program
    </p>
  </div>
);

export default ReferralGift;
