import './styles.scss';
import React from 'react';
import { injectIntl } from 'react-intl';

const BoxBuyToken = () => (
  <>
    <div className="box-buy-token">
      <p>Start for FREE now!!!</p>
      <p>
        0 <span>USD</span>
      </p>
      <button
        style={{
          width: '100%',
          background: 'linear-gradient(90deg, #52ffc0 0%, #00c291 100%)',
        }}
        type="button"
        className="btn-primary "
      >
        Buy Now
      </button>
      {/* <Input.Group compact>
          <Input style={{ width: '100%' }} defaultValue="5000" />
          <Select defaultValue="USDT">
            <Option value="USDT">
              <img src="/images/Home/feature/USD-select.png" alt="" /> USDT
            </Option>
            <Option value="MMR">
              <img src="/images/Home/feature/MMR-select.png" alt="" /> MMR
            </Option>
          </Select>
        </Input.Group>

        <Input.Group compact>
          <Input style={{ width: '100%' }} defaultValue="0.8511" />
          <Select defaultValue="MMR">
            <Option value="USDT">
              <img src="/images/Home/feature/USD-select.png" alt="" /> USDT
            </Option>
            <Option value="MMR">
              <img src="/images/Home/feature/MMR-select.png" alt="" /> MMR
            </Option>
          </Select>
        </Input.Group> */}
    </div>
  </>
);
export default injectIntl(BoxBuyToken);
