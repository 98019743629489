import './styles.scss';
import React from 'react';
import Banner from 'components/Banner';
import ReferralGift from 'components/ReferralGift';
import ShareLink from 'components/ShareLink';
import Badge from './component/badge';
import BackLink from 'components/BackLink';

const ReferralProgram = () => (
  <>
    <div className="warp-page-referral-program">
      <Banner showLink />

      <div className="warp-referral-program-content">
        <div className="referral-program-content">
          <div className="go-back">
            <BackLink label="Account" />
          </div>

          <div className="box-control-referral-program">
            <div className="item-control">
              <div>
                <img src="/images/icons/icon-earth.png" alt="" />
              </div>
              <div>
                <p>Referral Click</p>
                <p>5000</p>
              </div>
            </div>

            <div className="item-control">
              <div>
                <img src="/images/icons/icon-ticked.png" alt="" />
              </div>
              <div>
                <p>Pending Commission</p>
                <p>50</p>
              </div>
            </div>

            <div className="item-control">
              <div>
                <img src="/images/icons/icon-rocket-frame.png" alt="" />
              </div>
              <div>
                <p>Verified Commission</p>
                <p>500 $</p>
              </div>
            </div>
          </div>

          <ReferralGift />

          <div className="box-share-link">
            <div className="item-share-link">
              <p>Share Link</p>
              <p>Copy your personal referral link and share it with your friends and followers.</p>

              <ShareLink text="mmrocket.io/mia123456" />
            </div>

            <div className="item-share-link">
              <p>Ref Code</p>
              <p>Copy your personal referral code and share it with your friends and followers.</p>

              <ShareLink text="mia123456" />
            </div>

            <div className="item-share-link">
              <p>Add a referral badge</p>
              <p>Embed a visual badge on your website or content to link users directly to your referral url</p>

              <Badge />
            </div>
          </div>

          <div className="emble-code">
            <p>Emble Code</p>
            <ShareLink
              text={`<a href="https://www.digitalocean.com/?refcode=3a00d1f85444&utm_campaign=Referral_Invite&utm_medium=Referral_Program&utm_source=badge"><img src="https://web-platforms.sfo2.cdn.digitaloceanspaces.com/WWW/Badge%201.svg" alt="DigitalOcean Referral Badge" /></a>`}
            />
          </div>
        </div>
      </div>
    </div>
  </>
);

export default ReferralProgram;
