import './styles.scss';
import React from 'react';
import Banner from './component/banner';
import Control from './component/control';
import Action from './component/action';
import Feature from './component/feature';
import Contact from './component/contact';
import HistoryLogin from './component/historyLogin';

const Account = () => (
  <div className="warp-page-account">
    <Banner />
    <Control />
    <Action />
    <Feature />
    <Contact />
    <HistoryLogin />
  </div>
);

export default Account;
