import './styles.scss';
import React from 'react';
import { injectIntl } from 'react-intl';

const ItemFeature = ({ data, key }) => (
  <>
    <div className="item-feature" key={key}>
      <div className="item-feature-content">
        <img src={data.image} alt={data.name} />
        <h4>{data.name}</h4>
        <p>{data.description}</p>
      </div>
    </div>
  </>
);

export default injectIntl(ItemFeature);
