import './styles.scss';
import React from 'react';
import { Table } from 'antd';

const History = () => {
  const columns = [
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      render: (text) => <p className="time">{text}</p>,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text) => <p className="action">{text}</p>,
    },
    {
      title: 'Qut.',
      dataIndex: 'qty',
      key: 'qty',
      render: (text) => <p className="qty">{text}</p>,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text) => <p className="amount">{text}</p>,
    },
  ];

  const data = [
    {
      id: 1,
      time: `2022-02-09 14:01:48`,
      action: 'Buy MMPro (Month)',
      qty: '1',
      amount: '200$',
    },
    {
      id: 2,
      time: `2022-02-09 14:01:48`,
      action: 'Buy MMPro (Month)',
      qty: '2',
      amount: '500$',
    },
    {
      id: 3,
      time: `2022-02-09 14:01:48`,
      action: 'Buy MMPro (Month)',
      qty: '3',
      amount: '500$',
    },
    {
      id: 4,
      time: `2022-02-09 14:01:48`,
      action: 'Buy MMPro (Month)',
      qty: '2',
      amount: '400$',
    },
    {
      id: 5,
      time: `2022-02-09 14:01:48`,
      action: 'Buy MMPro (Month)',
      qty: '3',
      amount: '500$',
    },
    {
      id: 6,
      time: `2022-02-09 14:01:48`,
      action: 'Buy MMPro (Month)',
      qty: '1',
      amount: '200$',
    },
  ];

  return (
    <div>
      <p className="title-table-history">History</p>
      <Table rowKey="id" className="table-history" columns={columns} dataSource={data} />
    </div>
  );
};

export default History;
