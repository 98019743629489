// import './styles.scss';
import React from 'react';
import { injectIntl } from 'react-intl';
import { Col, Row } from 'antd';
import PackageItem from 'components/Package';

const dataPacake = [
  {
    name: 'Start ',
    description: 'For most people who want to do crypto trading operations in a smart way',
    uses: ['Powerful Dashboard', 'Ai Memory', '100.000.000 CUs', '300 CUs per second', '1 RPC Node (10M reqs/mon)'],
    // price: '$89',
    priceSale: '$0',
    status: 'active',
    duration: 'Month',
    buy: 'Get Started',
  },
  {
    name: 'Plus ',
    description: 'For most people who want to do crypto trading operations in a smart way',
    uses: ['Powerful Dashboard', 'Ai Memory', '400.000.000 CUs', '600 CUs per second', '10 RPC Node (40M reqs/mon)'],
    price: '$89',
    priceSale: '$49',
    status: 'active',
    duration: 'Month',
    buy: 'Get Started',
  },
  {
    name: 'Pro ',
    description: 'For most people who want to do crypto trading operations in a smart way',
    uses: [
      'Powerful Dashboard',
      'Ai Memory',
      '2.000.000.000 CUs',
      '2000 CUs per second',
      '20 RPC Nodes (200M reqs/mon)',
      'Bot target price function',
    ],
    price: '$189',
    priceSale: '$99',
    status: 'active',
    duration: 'Month',
    buy: 'Get Started',
  },
];

const Package = () => (
  <>
    <div id="pricing" className="package-home">
      <div className="title-block">
        <h2>Get Your Plan</h2>
        {/* <p>Become a professional trader by Register Now</p> */}
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
        className="content"
      >
        <div className="warp-package-bottom">
          <Row>
            {dataPacake.map((item, index) => (
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} key={index}>
                <PackageItem data={item} />
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </div>
  </>
);

export default injectIntl(Package);
