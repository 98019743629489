import './styles.scss';
import React from 'react';

const Package = ({ data, ...props }) => (
  <div className={data.status === 'active' ? 'package-item-info active' : 'package-item-info'} {...props}>
    <div className="wrapper-item">
      <div className="header-item">
        <p>{data.name}</p>
        <p>{data.description}</p>
      </div>
      <div className="body-item ">
        <ul>
          {data.uses.map((item, index) => (
            <li key={index}>
              <img src="/images/Home/package/icon-checked.png" alt="" /> {item}
            </li>
          ))}
        </ul>
      </div>
      <div className="footer-item">
        <div className="package-price">
          <p className="price">{data.price}</p>
          <p className="price-sale">
            {data.priceSale}
            <span>/{data.duration}</span>
          </p>
        </div>

        <div className="clearfix" />

        <button type="button" className="btn-primary">
          {data.buy}
        </button>
      </div>
    </div>
  </div>
);

export default Package;
