export const links = [
  // {
  //   label: 'Dashboard',
  //   href: '/',
  //   target: '',
  // },
  {
    label: 'Feature',
    href: '/coming-soon',
    target: '',
  },
  {
    label: 'Pricing',
    href: '#pricing',
    target: '',
  },
  // {
  //   label: '',
  //   href: '/coming-soon',
  //   target: '',
  // },
  {
    label: 'Documentation',
    href: 'https://beurl.app/mmr-whitepaper',
    target: '_blank',
  },
];

export default links;
