import './styles.scss';
import React from 'react';
import { injectIntl } from 'react-intl';
import { Col, Row } from 'antd';

const User = () => (
  <>
    <div className="user-home">
      <div className="title-block">
        <h2>Who use us?</h2>
        {/* <p>
          We believe MM Rocket is here to stay — and that a future worth building is one which opens its doors and invites
          everyone in.
        </p> */}
      </div>

      <div className="content">
        <Row gutter={[24, 24]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
            <div className="user-home-item">
              <div className="header-item">
                <img src="/images/Home/user/item-1.png" alt="" />
              </div>
              <div className="body-item">
                <h5>Researchers</h5>
                <p>Discover Token, Pair, Wallets’ trading data in 1 place</p>
              </div>
            </div>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
            <div className="user-home-item">
              <div className="header-item">
                <img src="/images/Home/user/item-2.png" alt="" />
              </div>
              <div className="body-item">
                <h5>Developers</h5>
                <p>APIs is built by Developers for Developers. Check out API Document now</p>
              </div>
            </div>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
            <div className="user-home-item">
              <div className="header-item">
                <img src="/images/Home/user/item-3.png" alt="" />
              </div>
              <div className="body-item">
                <h5>Community</h5>
                <p>Discover insight for trading or watching wallet acivities</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  </>
);

export default injectIntl(User);
