import './styles.scss';
import React from 'react';
import { Table } from 'antd';

const History = () => {
  const columnsMB = [
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      render: (text) => <p className="time">{text}</p>,
    },
    {
      title: 'Action/TxID',
      dataIndex: 'Action',
      render: (text, record) => (
        <div className="box-action-txid">
          <div>
            <p>{record.action}</p>
            <p className={record.status === 'Completed' ? 'completed' : 'pending'}>{record.status}</p>
          </div>
          <p>{record.TxID}</p>
        </div>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text) => <p className="amount">{text}</p>,
    },
  ];

  const columnsPC = [
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      render: (text) => <p className="time">{text}</p>,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text) => <p className="action">{text}</p>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text, record) => (
        <div className="box-action-txid">
          <div>
            <p className={record.status === 'Completed' ? 'completed' : 'pending'}>{record.status}</p>
          </div>
        </div>
      ),
    },
    {
      title: 'TxID',
      dataIndex: 'TxID',
      key: 'TxID',
      render: (text) => <p className="txid">{text}</p>,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text) => <p className="amount">{text}</p>,
    },
  ];

  const data = [
    {
      id: 1,
      time: `2022-02-09 14:01:48`,
      TxID: '0xc4c16a645...b21a',
      action: 'Deposit',
      status: 'Completed',
      amount: '200$',
    },
    {
      id: 2,
      time: `2022-02-09 14:01:48`,
      action: 'Deposit',
      status: 'Pending',
      TxID: '0xc4c16a645...b21a',
      amount: '200$',
    },
    {
      id: 3,
      time: `2022-02-09 14:01:48`,
      action: 'Deposit',
      status: 'Completed',
      TxID: '0xc4c16a645...b21a',
      amount: '200$',
    },
    {
      id: 4,
      time: `2022-02-09 14:01:48`,
      action: 'Deposit',
      status: 'Completed',
      TxID: '0xc4c16a645...b21a',
      amount: '200$',
    },
    {
      id: 5,
      time: `2022-02-09 14:01:48`,
      action: 'Deposit',
      status: 'Pending',
      TxID: '0xc4c16a645...b21a',
      amount: '200$',
    },
    {
      id: 6,
      time: `2022-02-09 14:01:48`,
      action: 'Deposit',
      status: 'Pending',
      TxID: '0xc4c16a645...b21a',
      amount: '200$',
    },
  ];

  return (
    <div>
      <p className="title-table-history">Recent Deposits</p>
      <Table rowKey="id" className="table-history" columns={window.innerWidth >= 991 ? columnsPC : columnsMB} dataSource={data} />
    </div>
  );
};

export default History;
