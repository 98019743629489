import './styles.scss';
import React from 'react';
import Banner from 'components/Banner';
import PointSecurity from 'components/PointSecurity';
import BackLink from 'components/BackLink';

const Security = () => (
  <>
    <div className="warp-page-security">
      <Banner showLink />

      <div className="warp-security-content">
        <div className="security-content">
          <div className="go-back">
            <BackLink label="Account" />
          </div>

          <div className="box-risk-level">
            <img className="icon-security-checked" src="/images/icons/icon-security-checked.png" alt="Security" /> Account risk
            level: <PointSecurity point={5} />
          </div>

          <div className="list-item-security">
            <div className="item-security">
              <div>
                <p>Password</p>
                <p>Increase your password strength to enhance account security</p>
              </div>
              <div>
                <button type="button">Change</button>
              </div>
            </div>
            <div className="item-security">
              <div>
                <p>Email Vertification</p>
                <p>Increase your password strength to enhance account security</p>
              </div>
              <div>
                <button type="button">Verified</button>
              </div>
            </div>
            <div className="item-security">
              <div>
                <p>MMR/Google Authenticator</p>
                <p>Set up your MMR/Google Authenticator to provide an extra security</p>
              </div>
              <div>
                <button type="button">Verified</button>
              </div>
            </div>
            <div className="item-security">
              <div>
                <p>Disable Account</p>
                <p>Disable your account immediately</p>
              </div>
              <div>
                <button type="button" className="disable">
                  Disable
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default Security;
