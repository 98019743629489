import './styles.scss';
import React from 'react';
import { injectIntl } from 'react-intl';

const Badge = () => (
  <div className="box-referral-badge">
    <div className="item-referral-badge active">
      <div>
        <p>Trading Exchange Bot</p>
        <p>
          <img src="/images/referral/referral-badge-1.png" alt="" /> MM Rocket
        </p>
      </div>
    </div>

    <div className="item-referral-badge">
      <div>
        <p>Trading Exchange Bot</p>
        <p>
          <img src="/images/referral/referral-badge-2.png" alt="" /> MM Rocket
        </p>
      </div>
    </div>

    <div className="item-referral-badge">
      <div>
        <p>Trading Exchange Bot</p>
        <p>
          <img src="/images/referral/referral-badge-3.png" alt="" /> MM Rocket
        </p>
      </div>
    </div>
  </div>
);

export default injectIntl(Badge);
