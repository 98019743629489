import React from 'react';
import Svg from '../Svg';

const Icon = (props) => (
  <Svg viewBox="0 0 20 20" {...props}>
    <path d="M2.55737 14.515V17.0289C2.55737 17.2604 2.7393 17.4423 2.97083 17.4423H5.48468C5.59218 17.4423 5.69968 17.401 5.7741 17.3183L14.8041 8.29658L11.7031 5.19562L2.68141 14.2173C2.59872 14.3 2.55737 14.3993 2.55737 14.515ZM17.2022 5.8985C17.5247 5.576 17.5247 5.05504 17.2022 4.73254L15.2672 2.79754C14.9447 2.47504 14.4237 2.47504 14.1012 2.79754L12.5879 4.31081L15.6889 7.41177L17.2022 5.8985Z" />
  </Svg>
);

export default Icon;
