import './styles.scss';
import React from 'react';
import SelectWithImage from 'widgets/SelectWithImage';
import QRCode from 'qrcode.react';
import { Row, Col } from 'antd';
import History from './history.js';
import BackLink from 'components/BackLink';
import Address from '../address';

const dataSelectCurrency = [
  {
    label: 'USDT',
    value: 'USDT',
    image: '/images/Home/feature/USD-select.png',
  },
  {
    label: 'MMR',
    value: 'MMR',
    image: '/images/Home/feature/MMR-select.png',
  },
];

const dataSelectDeposit = [
  {
    label: 'BNB Smart Chain (BEP20)',
    value: 'BSC',
    image: '/images/icons/BSC.png',
  },
  {
    label: 'DSD Smart Chain (BEP20)',
    value: 'BSD',
    image: '/images/icons/BSC.png',
  },
];

const Form = () => (
  <div className="warp-deposit-form">
    <div className="deposit-form">
      <div className="go-back">
        <BackLink label="Account" />
      </div>
      <Row gutter={[16, 16]} className="deposit-form-top">
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 16 }}>
          <div className="box-select-deposit">
            <div className="item-form">
              <SelectWithImage dataSelect={dataSelectCurrency} title="Select currency" defaultValue="USDT" />
            </div>
            <div className="item-form">
              <SelectWithImage dataSelect={dataSelectDeposit} title="Deposit to" defaultValue="BSC" />
            </div>
          </div>

          <div className="box-qr-code-title">
            <p>Address</p>
            <p>Use the wallet address below to deposit</p>
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
          <div className="box-qr-code">
            <div className="box-qr-code-address">
              <QRCode size={100} value="0xb99eac020a6b9370d1b36da40f810f5c468369fa" />
            </div>
            <div className="sub-title-qr-code">
              <p>Scan to deposit</p>
              <p>Powered by MM Rocket</p>
            </div>
          </div>
        </Col>
      </Row>

      <Address text="0xb99eac020a6b9370d1b36da40f810f5c468369fa" />

      <div className="box-expected">
        <div className="item-expected">
          <p>Expected arival</p>
          <p>15 network confirmations</p>
        </div>

        <div className="item-expected">
          <p>Expected arival</p>
          <p>15 network confirmations</p>
        </div>

        <div className="item-expected">
          <p>Expected arival</p>
          <p>15 network confirmations</p>
        </div>

        <div className="item-expected">
          <p>Sellected wallet</p>
          <p>Account Wallet</p>
        </div>
      </div>

      <div className="box-attention">
        <ul>
          <li>
            <p>Send only USDT to this deposit address</p>
          </li>
          <li>
            <p>
              Ensure the network is <span>BNB Smart Chain (BEP20)</span>
            </p>
          </li>
        </ul>
      </div>

      <History />
    </div>
  </div>
);

export default Form;
