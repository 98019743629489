import BoxBuyToken from 'modules/home/components/feature/BoxBuyToken';
import React from 'react';

function OneClickOneApi() {
  const dataSpecifications = [
    {
      name: 'APIs',
      value: '20+',
      image: '/images/Home/feature/countries.png',
    },
    {
      name: 'Trading Pairs',
      value: '1200k +',
      image: '/images/Home/feature/trading-swap.png',
    },
    {
      name: 'Blockchains',
      value: '4',
      image: '/images/Home/feature/trader.png',
    },
  ];
  return (
    <>
      <div className="box-swap">
        <div className="title-block">
          <h2>One click, One API.</h2>
          <p>Access all of our products from any where for any Web3 projects. Start for free now</p>
        </div>

        <div className="box-swap-content">
          <div className="box-image-background">
            <div className="box-earth" style={{ background: `url('/images//Home/feature/mapbase.png')` }}>
              <img className="background-earth" src="/images/Home/feature/earth-dark.png" alt="" />
            </div>
            <img className="background-circle-1" src="/images/Home/feature/circle-1.png" alt="" />
            <img className="background-circle-2" src="/images/Home/feature/circle-2.png" alt="" />
            <img className="background-rocket" src="/images/Home/feature/rocket.png" alt="" />
            <img className="background-bitcoin" src="/images/Home/feature/bitcoin.png" alt="" />
            <img className="background-bnb" src="/images/Home/feature/bnb.png" alt="" />
            <img className="background-ethereum" src="/images/Home/feature/ethereum.png" alt="" />
            <img className="background-usdt" src="/images/Home/feature/usdt.png" alt="" />
          </div>

          <div className="specifications-box">
            {dataSpecifications.map((item, index) => (
              <div className="specifications-item" key={index}>
                <div>
                  <img src={item.image} alt={item.name} />
                </div>
                <div>
                  <p>{item.value}</p>
                  <p>{item.name}</p>
                </div>
              </div>
            ))}
          </div>

          <BoxBuyToken />
        </div>

        <img className="background-home-3" src="/images/Home/background/bg-3.png" alt="" />
      </div>
    </>
  );
}

export default OneClickOneApi;
