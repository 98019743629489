import './styles.scss';
import React from 'react';
import { Row, Col } from 'antd';

const Contact = () => (
  <div className="warp-account-contact">
    <div className="account-contact">
      <Row
        gutter={[
          { xs: 14, sm: 16, md: 20, lg: 26 },
          { xs: 14, sm: 16, md: 20, lg: 26 },
        ]}
        justify="center"
      >
        <Col xs={{ span: 24 }} sm={{ span: 14 }} md={{ span: 16 }} lg={{ span: 18 }}>
          <div className="item-contact-left">
            <img className="background-contact" src="/images/account/banner-contact-left.png" alt="" />
            <div>
              <p>Don't Miss The Moment</p>
              <p>Learn directly exchange trading bot</p>
            </div>
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 10 }} md={{ span: 8 }} lg={{ span: 6 }}>
          <div className="item-contact-right">
            <div>
              <img src="/images/account/icon-question-mark.png" alt="" />
            </div>
            <img className="background-contact" src="/images/account/banner-contact-right.png" alt="" />
            <div>
              <p>Need help?</p>
              <p>Please check our docs</p>
              <button type="button">Contact Support</button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  </div>
);

export default Contact;
