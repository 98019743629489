import './styles.scss';
import React from 'react';

const Trading = () => (
  <div className="trading-home">
    <div className="title-block">
      <h2>Build your Web3 projects</h2>
      <p>A powerful cryptocurrency exchange in your pocket. Buy, sell and trade crypto on the go.</p>
    </div>

    <div
      style={{
        padding: '0 15px',
      }}
      className="content"
    >
      <div className="box-background">
        <img className="bg-all" src="/images/Home/trading/bg.png" alt="" />
        <div className="list-bg-image">
          <img src="/images/Home/trading/Phone.png" alt="" />
          <img src="/images/Home/trading/Card.png" alt="" />
          <img src="/images/Home/trading/icon-bg-1.png" alt="" />
          <img src="/images/Home/trading/icon-bg-2.png" alt="" />
          <img src="/images/Home/trading/icon-bg-3.png" alt="" />
        </div>
      </div>

      <button type="button" className="btn-primary">
        Exchange Now
      </button>
    </div>
  </div>
);

export default Trading;
